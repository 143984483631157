// @flow

import React, { Component, ComponentType } from 'react';
import { getDisplayName } from './index';
import Alert from '../elements/Alert';
import StepError from '../../app/StepError';


type Props = {
  appState: {},
  isFirst: boolean,
  isLast: boolean,
  isActive: boolean,
  goNext: () => any,
  goBack: () => any,
  reset: () => any,
  startOver: () => any,
  updateAppState: (data: {}, cb?: () => void) => void,
} & StepConfig;

type State = {
  enterLoading: boolean,
  submitLoading: boolean,
  enterApiError: ?AlertT,
  submitApiError: ?AlertT,
};

export default function createView(WrappedComponent: ComponentType<any>) {
  class CreateView extends Component<Props, State> {
    constructor(props: Props) {
      super(props);

      const { enterApi } = props;

      const enterLoading = !!enterApi;

      this.state = {
        // Should be true by default if step has onEnter api call
        // to prevent initial rendering with enterLoading = false
        enterLoading,
        submitLoading: false,
        enterApiError: null,
        submitApiError: null,
      };
    }

    componentDidMount() {
      this.init();
    }

    init = () => {
      this.setState({
        enterApiError: null,
      }, () => {
        this.callApi('enter');
      });
    };

    submit = (...args: any[]) => {
      const {
        updateAppState,
        normalizeUserInput,
        submitApi,
        goNext,
      } = this.props;


      updateAppState(normalizeUserInput ? normalizeUserInput(...args) : {}, () => {
        if (submitApi) {
          this.callApi('submit');
        } else {
          goNext();
        }
      });
    };

    callApi = (type: 'enter' | 'submit') => {
      const {
        appState,
        enterApi,
        submitApi,
        updateAppState,
        goNext,
      } = this.props;
      const api = type === 'enter' ? enterApi : submitApi;

      if (api) {
        const { call, normalizeResponse, normalizeError } = api;

        this.setState({ [`${type}Loading`]: true });

        let cl = call(appState);
        if (Array.isArray(cl)) {
          Promise.all(cl)
            .then(res => {
              const { appState: updatedState } = this.props;

              updateAppState(normalizeResponse(res, updatedState), () => {
                this.setState({ [`${type}Loading`]: false });

                if (type === "submit") {
                  goNext();
                }
              });
            })
            .catch(err => {
              const { appState: updatedState } = this.props;

              this.setState({
                [`${type}Loading`]: false,
                [`${type}ApiError`]: normalizeError(err, updatedState)
              });
            });
        } else {
          Promise.resolve(cl)
            .then(res => {
              const { appState: updatedState } = this.props;

              updateAppState(normalizeResponse(res, updatedState), () => {
                this.setState({ [`${type}Loading`]: false });

                if (type === "submit") {
                  goNext();
                }
              });
            })
            .catch(err => {
              const { appState: updatedState } = this.props;

              this.setState({
                [`${type}Loading`]: false,
                [`${type}ApiError`]: normalizeError(err, updatedState)
              });
            });
        }
      }
    };

    render() {
      const {
        appState,
        mapStateToView,
        buildLink,
        checkRequiredData,
        enterApi,
        submitApi,
        updateAppState,
        ...viewProps
      } = this.props;

      const {
        enterLoading,
        submitLoading,
        enterApiError,
        submitApiError,
      } = this.state;

      const mappedState = mapStateToView ? mapStateToView(appState) : {};

      if (enterApiError) {
        return (
          <>
            <Alert {...enterApiError} />

            <StepError
              onTryAgain={this.init}
              onReset={viewProps.reset}
              isFirstStep={viewProps.isFirst}
            />
          </>
        );
      }

      return (
        <WrappedComponent
          alert={submitApiError}
          {...viewProps}
          {...mappedState}
          enterLoading={enterLoading}
          submitLoading={submitLoading}
          submit={this.submit}
        />
      );
    }
  }

  CreateView.displayName = `CreateView(${getDisplayName(WrappedComponent)})`;

  return CreateView;
}
