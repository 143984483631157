// @flow

import axios from "axios";

/**
 * Base API URL
 */
const API_URL = process.env.REACT_APP_BACKPLANE_API_URL;

/**
 * Default axios configuration
 */
const baseConfig = {
  baseURL: API_URL,
  timeout: 30000,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json"
  }
};

const createCallApi = (overrides: {} = {}) =>
  axios.create({ ...baseConfig, ...overrides });
export default createCallApi;
