// @flow

import React, { Component } from 'react';
import { matchPath } from 'react-router-dom';
import { compose } from 'recompose';
import localStorage from 'store';
import expirePlugin from 'store/plugins/expire';
import cn from 'classnames';
import Alert from '../../common/elements/Alert';
import Step from '../Step';
import history from '../../common/history';
import { withStyles, withConfig, StepProvider } from '../../common/utils';
import styles from './Process.jss';

localStorage.addPlugin(expirePlugin);

type Props = {
  config: BrandConfig,
} & Styles<typeof styles>;

type State = {
  /**
   * All previous steps have required data
   */
  hasRequiredData: boolean,

  /**
   * Index of currently active step
   */
  activeStepIndex: number,

  /**
   * Check-in process state
   */
  appState: {},
};

function getActiveStepIndex(steps) {
  // Get index of currently active Step by path
  return steps.findIndex(s => matchPath(history.location.pathname, {
    path: s.path,
    exact: true,
  }));
}

class Process extends Component<Props, State> {
  constructor(props) {
    super(props);

    const { config } = this.props;
    const { steps } = config;

    const activeStepIndex = getActiveStepIndex(steps);

    // Use state from local storage if exist or initialState otherwise
    // TODO: generate a unique key upon each compile by webpack and use instead of 'olci'.
    // This will allow testing of localstorage version of site, but bust localstorage cache upon each code change
    let appState;
    if (process.env.NODE_ENV === 'development') appState = config.initialState
    else appState = localStorage.get('olci') || config.initialState;

    // Check if all previous steps have required data
    const hasRequiredData = steps.slice(0, activeStepIndex).every(
      s => typeof s.checkRequiredData !== 'function' || s.checkRequiredData(appState),
    );

    this.state = {
      activeStepIndex: 0,
      hasRequiredData,
      appState,
    };
  }

  static getDerivedStateFromProps(props) {
    return {
      activeStepIndex: getActiveStepIndex(props.config.steps),
    };
  }

  componentDidMount() {
    const { hasRequiredData } = this.state;

    if (!hasRequiredData) {
      this.reset();
    }
  }

  updateAppState = (data: {}, cb?: () => void) => {
    const { appState } = this.state;

    this.setState({
      appState: {
        ...appState,
        ...data,
      },
    }, () => {
      // Auto-expire local storage cache in 15 minutes
      localStorage.set('olci', this.state.appState, new Date().getTime() + (15 * 60 * 1000));

      if (cb) {
        cb();
      }
    });
  };

  reset = (redirectUrl?: string) => {
    const { config } = this.props;

    // Clear the cache
    localStorage.remove('olci');

    // Redirect to the first Step
    history.push(redirectUrl || config.steps[0].path);

    // Update the local state
    this.setState({
      hasRequiredData: true,
      activeStepIndex: 0,
      appState: config.initialState,
    });
  };

  startOver = () => {
    const { startOverUrl } = this.props.config;
    const { appState } = this.state;
    const url = startOverUrl && startOverUrl(appState);

    this.reset(url);
  };

  goNext = () => {
    const { steps } = this.props.config;
    const { activeStepIndex, appState } = this.state;

    if (activeStepIndex !== steps.length - 1) {
      const nextStepIndex = activeStepIndex + 1;
      const nextStep = steps[nextStepIndex];

      const nextLink = nextStep.buildLink ? nextStep.buildLink(appState) : nextStep.path;

      history.push(nextLink);

      this.setState({
        activeStepIndex: nextStepIndex,
      });
    }
  };

  goBack = () => {
    const { steps } = this.props.config;
    const { activeStepIndex, appState } = this.state;

    if (activeStepIndex !== 0) {
      const prevStepIndex = activeStepIndex - 1;
      const prevStep = steps[prevStepIndex];

      const prevLink = prevStep.buildLink ? prevStep.buildLink(appState) : prevStep.path;

      history.push(prevLink);

      this.setState({
        activeStepIndex: prevStepIndex,
      });
    }
  };

  getProps = (step: StepConfig, index: number) => {
    const { config } = this.props;

    const {
      activeStepIndex,
      appState,
    } = this.state;

    const steps = config.steps || [];

    return {
      isFirst: activeStepIndex === 0,
      isLast: activeStepIndex === steps.length - 1,
      isActive: index === activeStepIndex,
      isDone: index < activeStepIndex,
      goNext: () => this.goNext(),
      goBack: () => this.goBack(),
      updateAppState: this.updateAppState,
      reset: () => this.reset(),
      startOver: () => this.startOver(),
      appState,
      ...step,
    };
  };

  renderSteps() {
    const { config } = this.props;

    const {
      hasRequiredData,
    } = this.state;

    const steps = config.steps || [];

    if (!steps.length) {
      return (
        <Alert type="warning" title="Steps were not configured" />
      );
    }

    if (!hasRequiredData) {
      return null;
    }

    return steps.map((step, index) => (
      <StepProvider key={step.title} value={this.getProps(step, index)}>
        <Step {...this.getProps(step, index)} />
      </StepProvider>
    ));
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={cn('process', classes.process)}>
        {this.renderSteps()}
      </div>
    );
  }
}

export default compose(
  withConfig,
  withStyles(styles),
)(Process);
