// @flow

import c from 'color';

type StyleValue = string | number;
type Overrides = { [string]: StyleValue | StyleValue[] };

function createTheme(overrides: Overrides = {}) {
  const colorRedeam = c('white');
  const colorRedeamLighter = colorRedeam.fade(0.5).string();
  const colorRedeamDarken = colorRedeam.fade(0.6).string();

  const theme = {
    /**
     * Defaults
     */
    colorPrimary: '#293675',
    colorSecondary: '#5eb6c1',
    colorError: '#f04134',
    colorWarning: '#ffbb3a',
    colorSuccess: '#289f38',
    colorText: '#293675',
    colorHelper: '#40a8f5',

    /**
     * Overrides
     */
    boxBackground: `linear-gradient(170deg, ${colorRedeamLighter} 45%, ${colorRedeamDarken} 45%)`,
    elementBackground: c('#293675').fade(0.9).string(),
    get preloaderColor() { return this.colorPrimary; },
    get textColor() { return this.colorText; },
    get processColor() { return this.colorSecondary; },
    get processActiveColor() { return this.colorPrimary; },
    get stepTitleColor() { return this.colorSecondary; },
    get stepActiveTitleColor() { return this.colorPrimary; },
    get stepHintColor() { return this.colorSecondary; },
    get stepActiveHintColor() { return this.colorSecondary; },
    get borderColor() { return this.colorPrimary; },

    // Inputs
    get inputBorderColor() { return c(this.borderColor).fade(0.4).string(); },
    get inputFocusBorderColor() { return this.borderColor; },
    get inputErrorBorderColor() { return this.colorError; },
    get inputTextColor() { return this.colorText; },
    get inputPlaceholderColor() { return c(this.colorText).fade(0.5).string(); },

    // Buttons
    get buttonBorderColor() { return this.inputBorderColor; },
    get buttonHoverBorderColor() { return this.inputFocusBorderColor; },
    get buttonTextColor() { return this.colorPrimary; },
    get buttonBackgroundColor() { return this.elementBackground; },
    get buttonActiveColor() { return 'white'; },
    get buttonDisabledBorderColor() { return this.inputPlaceholderColor; },
    get buttonDisabledBackground() { return 'transparent'; },
    get buttonDisabledColor() { return this.inputPlaceholderColor; },

    // Date picker globals
    get datePickerMonthBackground() { return this.elementBackground; },
    get datePickerTitleColor() { return this.textColor; },

    // Day colors
    get datePickerDayBorderColor() { return this.colorPrimary; },
    get datePickerDayBackground() { return this.elementBackground; },
    get datePickerDayColor() { return this.textColor; },

    // Day hover colors
    get datePickerDayHoverBorderColor() { return this.colorPrimary; },
    get datePickerDayHoverBackground() { return this.elementBackground; },
    get datePickerDayHoverColor() { return this.colorSecondary; },

    // Day selected colors
    get datePickerDaySelectedBorderColor() { return this.textColor; },
    get datePickerDaySelectedBackground() { return this.colorPrimary; },
    get datePickerDaySelectedColor() { return 'white'; },

    // Day disabled colors
    get datePickerDayDisabledBorderColor() { return 'transparent'; },
    get datePickerDayDisabledBackground() { return 'transparent'; },
    get datePickerDayDisabledColor() { return this.inputPlaceholderColor; },

    ...overrides,
  };

  return { ...theme };
}

export default createTheme;
